import { Topic } from '../models/Topic';
import getTopicStatus from './getTopicStatus';

function getTopicCurrPrice(topic: Topic) {

  if(!topic.sku) return [0,0];

  const status = getTopicStatus(topic);

  let fallbackDiscount = topic.sku.discounts.normal;

  if(topic.sku.times.early_bird_start !== undefined)
    fallbackDiscount = topic.sku.discounts.earlybird;

  if(topic.sku.times.pre_order_start !== undefined)
    fallbackDiscount = topic.sku.discounts.preorder;

  if(status === 'notInDuration')
    return [topic.sku.price, topic.sku.price - fallbackDiscount];

  if(status === 'free') return [0,0];

  return [topic.sku.price, topic.sku.price - topic.sku.discounts[status]];
}

export default getTopicCurrPrice;