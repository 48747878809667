import PageHead from '@components/PageHead';
import { useScrollPosition } from '@hooks/useScrollPosition';
import React, { useState } from 'react';
import { Topic } from '@models/Topic';
import getTopicCurrPrice from '@helpers/getTopicCurrPrice';
import { useRouter } from 'next/router';

export default function LandingPage(props: { courses: Topic[] }) {

  const [, setScrollY] = useState(0);
  const [isShowingQRCode, setShowingQRCode] = useState(false);

  const router = useRouter();

  useScrollPosition(
    ({ currPos }) => {
      setScrollY(-1 * currPos.y);
    },
    [],
    null,
    false,
    0
  );

  function getScale() {

    if (typeof document === 'undefined') return 1;

    const e = document
      .getElementById('animated-phone')?.getBoundingClientRect();

    if (!e) return 1;

    const t = e.top - window.innerHeight * 2 / 3;
    const b = e.bottom;
    const h = b - t;
    const c = 1 + ((-1 * t / h) * 0.5);

    if (c < 0.6) return 0.6;
    if (c > 1.2) return 1.2;

    return c;
  }

  function handleDownloadClick() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      .test(navigator.userAgent)) {
      window.open(
        'https://download.hoost.tw/vA72?pid=hoost&c=header&utm_source=hoost&utm_medium=header&utm_campaign=download',
        '_blank').focus();
    } else {
      setShowingQRCode(i => !i);
    }
  }

  return (
    <div className="flex flex-col items-center landing-page">
      <PageHead/>

      <div
        className="mt-36 flex flex-row flex-wrap container px-0 md:px-4
      lg:px-8 py-20 items-center">
        <div className="w-full lg:w-2/5">
          <h1
            className="text-center text-4xl 2xl:text-7xl text-white
           font-bold lg:text-left"
          >
            成為終生學習者
          </h1>
          <h2
            className="text-2xl lg:text-4xl 2xl:text-5xl text-center
            text-white mt-6 font-bold lg:text-left"
          >
            線上語音課程 • Podcast
          </h2>
          <div
            className="flex flex-row w-full justify-evenly
            mt-8 lg:justify-start px-4 md:px-0"
          >
            <div className="h-10 px-4 md:px-0">
              <img
                className="w-full cursor-pointer"
                src={'/images/download_ios.png'}
                onClick={() => {
                  if (window?.fbq) window.fbq('track', 'iOSdownload');
                  window.location.href =
                    'https://download.hoost.tw/vA72?pid=hoost&c=home-iOS&utm_source=hoost&utm_medium=home-iOS&utm_campaign=download';
                }}
                alt=""
              />
            </div>
            <div className="h-10 lg:ml-4 px-4 md:px-0">
              <img
                className="w-full cursor-pointer"
                src={'/images/download_android.png'}
                onClick={() => {
                  if (window?.fbq) window.fbq('track', 'AndroidDownload');
                  window.location.href =
                    'https://download.hoost.tw/vA72?pid=hoost&c=home-Android&utm_source=hoost&utm_medium=home-Android&utm_campaign=download';
                }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="mt-24 w-full lg:w-3/5 lg:mt-0 container">
          <div
            className="flex flex-row w-full flex-wrap lg:justify-end
           xl:h-20 md:h-16 h-14 h-14 overflow-hidden justify-center px-8"
          >
            {[1, 2, 3, 4, 5, 6, 7].map((i) => (
              <img
                className="mx-2 xl:h-20 md:h-16 h-14"
                key={i}
                src={'/images/creators-rounded/1/' + i + '.png'}
                alt=""
              />
            ))}
          </div>
          <div
            className="flex flex-row w-full flex-wrap lg:justify-end
             xl:h-20 md:h-16 h-14 overflow-hidden mt-4 justify-center"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <img
                className="mx-2 xl:h-20 md:h-16 h-14"
                key={i}
                src={'/images/creators-rounded/2/' + i + '.png'}
                alt=""
              />
            ))}
          </div>
          <div
            className="flex flex-row mt-4 flex-wrap lg:justify-end
              xl:h-20 md:h-16 h-14 overflow-hidden justify-center px-8"
          >
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <img
                className="mx-2 xl:h-20 md:h-16 h-14"
                key={i}
                src={'/images/creators-rounded/3/' + i + '.png'}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>

      <div className="mt-24 px-4 flex flex-col items-center container lg:px-8">
        <h2
          className="text-center text-3xl text-white
        font-bold mb-4 lg:hidden"
        >
          熱門課程
        </h2>

        <div className="lg:grid lg:grid-cols-3 w-full lg:gap-8">
          {props.courses.map((i) => (
            <div
              onClick={async () => {
                await router.push('/topic/' + i.id);
              }}
              key={i.id}
              className="flex flex-row py-6 border-b-2 lg:flex-wrap
              cursor-pointer border-gray-700 w-full lg:mb-8
              lg:border-none items-start"
            >
              <div className="fixed-ratio-image-container hidden lg:block">
                <img
                  className="object-cover w-full relative rounded-lg"
                  src={i.banner || i.image}
                  alt=""
                />
                <p
                  className="absolute top-2 left-2 py-1 px-2 bg-grey-900
                text-white bg-opacity-90 rounded-lg">
                  語音課程
                </p>
              </div>
              <div className="lg:hidden w-1/4">
                <img
                  className="object-cover w-full relative rounded-lg"
                  src={i.image}
                  alt=""
                />
              </div>
              <div
                className="ml-3 lg:ml-0 lg:mt-2 lg:w-full mt:h-48 w-3/4 flex
              flex-col justify-center lg:justify-start">
                <p className="text-white lg:text-2xl text-lg font-bold">
                  {i.name}
                </p>
                <p
                  className="text-white opacity-70 mt-2 text-sm
                lg:text-lg mt-1/2">
                  {i.podcast.title}
                </p>
                <div className="flex flex-row items-center">
                  <p
                    className="text-yellow-600 font-bold
                   text-sm lg:text-lg mt-1/2">
                    NT${getTopicCurrPrice(i)[1]}
                  </p>
                  <p
                    className="ml-2 text-grey-400 font-bold text-sm lg:text-lg
                   line-through"
                  >
                    NT${i.skus[0].price}
                  </p>
                </div>
                <p className="text-white opacity-70 lg:text-lg text-sm mt-1/2">
                  {i.skus[0].metadata ? (Math.round(
                    i.skus[0].metadata.except_total_audio_length / 6) / 10) +
                    '小時 •' : ''}
                  免費上 {i.free_listen_count} 堂課
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="my-16 px-8 container flex flex-row flex-wrap
      items-center"
      >
        <div className="w-full px-4 lg:w-1/2 lg:px-24 xl:px-36 xl:py-16">
          <img
            id="animated-phone"
            style={{
              transform: `scale(${getScale()}) 
              rotate(${30 - ((getScale() - 1) / 0.2) * 30}deg)`,
              transition: 'none'
            }}
            className="w-full my-24"
            src={'/images/phone.png'}
            alt=""
          />
        </div>
        <div className="w-full lg:w-1/2 lg:px-12">
          <div className="mt-16">
            <div className="flex flex-row items-center">
              <img src={'/images/boy1.svg'} className="w-8 md:w-auto" alt=""/>
              <p className="text-grey-300 md:text-2xl text-lg font-bold ml-3">
                Chris
              </p>
            </div>
            <p className="text-white text-sm md:text-lg mt-4">
              原本擔心會不習慣語音課程xD。試上了 Ina 的課程後，發現不只
              Podcast，還有簡報跟文稿！我自己覺得聽 Podcast
              上課，反而會更認真，不像影片課程，有時候看東看西，注意力就飄走了，很推薦！
            </p>
          </div>
          <div className="mt-8">
            <div className="flex flex-row items-center">
              <img src={'/images/girl1.svg'} className="w-8 md:w-auto" alt=""/>
              <p className="text-grey-300 md:text-2xl text-lg font-bold ml-3">
                Yvette Luo
              </p>
            </div>
            <p className="text-white text-sm mt-4 md:text-lg">
              很棒的播放體驗，速度可以自由調節，播完都會有記錄，設計非常喜歡，特色的回覆功能超級貼心，有別於其他
              Podcast 的設計，能與播客直接互動！
            </p>
          </div>
          <div className="mt-8">
            <div className="flex flex-row items-center">
              <img src={'/images/girl2.svg'} className="w-8 md:w-auto" alt=""/>
              <p className="text-grey-300 ml-3 text-lg font-bold md:text-2xl">
                Kayla
              </p>
            </div>
            <p className="text-white text-sm mt-4 md:text-lg">
              在優惠時入手了外商英文課程，內容跟工作實務上相關性高，覺得用
              Podcast 學習的效率很高，希望 Hoost 未來成為無時無刻的學習助教！
            </p>
          </div>
          <div
            className="w-full flex flex-row justify-center
           md:justify-start my-16"
          >

            <div
              className="border-2 border-white rounded-full relative
            md:px-16 px-8 py-6 cursor-pointer flex flex-row items-center"
              onClick={handleDownloadClick}
            >
              <div
                style={{
                  left: 40,
                  bottom: 88
                }}
                className={`bg-white rounded-xl transition-all p-4 w-64
            absolute` + (isShowingQRCode ? ' ' : ' hidden')}>
                <p className="text-center font-bold">掃描 QR Code 下載 App</p>
                <img
                  src={'/images/qr-code.svg'}
                  className="rounded-xl overflow-hidden mt-2"
                  alt=""/>
              </div>
              <img src={'/icons/qr-code.svg'} className="mr-3 w-7 h-7" alt=""/>
              <p className="text-white text-xl lg:text-2xl font-bold">
                免費下載 Hoost
              </p>
            </div>

            {isShowingQRCode && <div
              className="w-full h-full fixed top-0 left-0"
              onClick={() => setShowingQRCode(false)}/>}

          </div>
        </div>
      </div>
    </div>
  );
}
