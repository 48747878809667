import config from 'config';
import Head from 'next/head';
import React from 'react';

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
  pageUrl?: string;
}

function PageHead(props: Props) {
  const {
    title,
    description,
    pageUrl,
    imageUrl
  } = props;

  return (
    <Head>
      <title>{title || config.DEFAULT_PAGE_TITLE}</title>
      <meta charSet="utf-8"/>
      <link rel="manifest" href="/manifest.json"/>
      <meta name="theme-color" content="#333"/>
      <meta
        name="viewport"
        content="initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta
        name="google-site-verification"
        content="XsHLluikSGv1h0yVp7toNjnrkIXDmt12waGjgafkQRM"
      />
      <meta
        name="description"
        content={description || config.DEFAULT_PAGE_DESCRIPTION}
      />
      <meta property="og:title" content={title || config.DEFAULT_PAGE_TITLE}/>
      <meta
        property="og:description"
        content={description || config.DEFAULT_PAGE_DESCRIPTION}
      />
      <meta
        property="og:image"
        content={imageUrl || config.DEFAULT_PAGE_IMAGE_URL}
      />
      <meta property="og:url" content={pageUrl || config.DEFAULT_PAGE_URL}/>
      <meta property="og:type" content="website"/>
      <link rel="canonical" href={pageUrl || config.DEFAULT_PAGE_URL}/>
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;700;900&family=Roboto:wght@400;500;700;900&display=swap"
        rel="stylesheet"
        as="font"
      />
      <link rel="icon" href="/icons/favicon.svg"/>
      <link rel="shortcut icon" type="image/png" href="/icons/favicon.svg"/>

      <script
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"></script>
      <script type="text/javascript" src="/js/apple.js"></script>

      {process.env.ENV === 'production' && (
        <React.Fragment>
          <script type="text/javascript" src="/js/hotjar.js"></script>
          <script type="text/javascript" src="/js/gtm.js"></script>
          <script type="text/javascript" src="/js/fbpixel.js"></script>

          <noscript>
            <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=1251057895258339&ev=PageView&noscript=1"
              alt=""
            />
          </noscript>
        </React.Fragment>
      )}
    </Head>
  );
}

export default PageHead;
