import { Topic } from '../models/Topic';

type TopicStatus =
  'normal' | 'preorder' | 'earlybird' | 'notInDuration' | 'free'

/**
 * 取得主題當前的狀態
 * @param topic
 * @return TopicStatus
 */
function getTopicStatus(topic: Topic): TopicStatus {

  if(!topic.sku) return 'free';

  let topicType: TopicStatus = 'notInDuration';

  if (new Date().getTime() >=
    new Date(topic.sku.times.pre_order_start).getTime() &&
    new Date().getTime() <
    new Date(topic.sku.times.pre_order_end).getTime()) {
    topicType = 'preorder';
  } else if (new Date().getTime() >=
    new Date(topic.sku.times.early_bird_start).getTime() &&
    new Date().getTime() <
    new Date(topic.sku.times.early_bird_end).getTime()) {
    topicType = 'earlybird';
  } else if (new Date().getTime() >=
    new Date(topic.sku.times.normal_sale_start).getTime() &&
    new Date().getTime() <
    new Date(topic.sku.times.normal_sale_end).getTime()) {
    topicType = 'normal';
  }

  return topicType;
}

export default getTopicStatus;
