import React from 'react';
import LandingPage from '../src/pages/LandingPage';
import API from '@services/index';
import { Topic } from '@models/Topic';

export default function Page(props: { courses: Topic[] }) {
  return <LandingPage courses={props.courses}/>;
}

export async function getServerSideProps() {
  const res = await API.getHomePageCourses();

  let courses: Topic[] = [];

  if (res.data.result) {
    courses = res.data.datas;
  }

  return { props: { courses } };
}
